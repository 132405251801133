.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


@keyframes gradient-wave {
    0% {
        background-position: 0 50%;
    }
    25% {
        background-position: 50% 100%;
    }
    50% {
        background-position: 100% 50%;
    }
    75% {
        background-position: 50% 0;
    }
    100% {
        background-position: 0 50%;
    }
}

.gradient-wave {
    background: linear-gradient(270deg, #005fbd, #18b073, #ff6b6b, #feca57, #54a0ff, #9b59b6, #3498db, #2ecc71);
    background-size: 800% 800%;
    animation: gradient-wave 50s ease infinite;
}
